import { getConfig } from '@customer-frontend/config';
import { getSecondaryButtonPalette } from '@customer-frontend/quiz';
import {
  Button,
  ButtonPalette,
  LinkButton,
  Modal,
  Typography,
} from '@eucalyptusvc/design-system';
import { useState } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

interface FURConsultChoiceProps {
  linkButtonPalette: ButtonPalette;
  onPhoneSelected: () => void;
  onTextSelected: () => void;
}

export const FURConsultChoice: React.FC<FURConsultChoiceProps> = ({
  linkButtonPalette,
  onPhoneSelected,
  onTextSelected,
}) => {
  const [showMethodModal, setShowMethodModal] = useState(false);
  const config = getConfig();

  return (
    <>
      <div className="flex flex-col h-full space-y-6 text-center">
        <Typography size="lg" isBold>
          <FormattedMessage defaultMessage="How do you want to be contacted for your consult?" />
        </Typography>
        <div className="space-y-3">
          <Typography size="medium-paragraph">
            <FormattedMessage defaultMessage="Your practitioner will use this method to get in touch with you." />
          </Typography>
        </div>
        <div>
          <LinkButton
            onClick={() => setShowMethodModal(true)}
            iconLeft={<FaRegQuestionCircle />}
            palette={linkButtonPalette}
          >
            <Typography size="small-text" isBold>
              <FormattedMessage defaultMessage="Which method will work best for me?" />
            </Typography>
          </LinkButton>
        </div>
        <div className="flex flex-col space-y-2">
          <Button
            level="secondary"
            palette={getSecondaryButtonPalette(config.brand)}
            onClick={onPhoneSelected}
          >
            <FormattedMessage defaultMessage="Phone call" />
          </Button>
          <Button
            level="secondary"
            palette={getSecondaryButtonPalette(config.brand)}
            onClick={onTextSelected}
          >
            <FormattedMessage defaultMessage="Text-based chat" />
          </Button>
        </div>
      </div>
      <Modal
        isOpen={showMethodModal}
        title="Which method will work best for me?"
        onClose={() => setShowMethodModal(false)}
      >
        <div className="flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-2">
            <Typography size="medium-paragraph" isBold>
              <FormattedMessage defaultMessage="Phone call" />
            </Typography>
            <Typography size="medium-paragraph">
              <FormattedMessage defaultMessage="Some people find it easier to talk to a practitioner on a phone call." />
            </Typography>
            <Typography size="medium-paragraph">
              <FormattedMessage defaultMessage="If you choose to be contacted by phone call, you’ll get to select a date and time window for the call that works for you." />
            </Typography>
          </div>
          <div className="flex flex-col gap-y-2">
            <Typography size="medium-paragraph" isBold>
              <FormattedMessage defaultMessage="Text-based chat" />
            </Typography>
            <Typography size="medium-paragraph">
              <FormattedMessage defaultMessage="Other people prefer text-based chat for its convenience/accessibility. It may be a more comfortable option for patients who have a hearing impairment or who speak English as a second language." />
            </Typography>
            <Typography size="medium-paragraph">
              <FormattedMessage defaultMessage="If you choose to be contacted by text-based chat, a practitioner will send you a message (usually within 48hrs)." />
            </Typography>
          </div>
        </div>
      </Modal>
    </>
  );
};
