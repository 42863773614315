import React, { ReactElement } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  RouteProps,
} from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import {
  PrivateRoute,
  PrivateRouteProps,
  RouteTracker,
  ScrollToTop,
} from '@customer-frontend/route';
import { PersistedDiscountFromURLProvider } from '@customer-frontend/order';
import { routes } from 'utils/routes';
import { NavBarLayout } from 'components/navigation/navbar-layout';
import ProfileLayout from './profile/layout';
import { OfferingLayout } from '@customer-frontend/page-templates';
import { CookieConsentManager } from '../components/navigation/consent/cookie-consent-manager';
import ActivatePurchaseFlow from './consultation/flexiplans/activate-purchase-flow';
import { environment } from 'environment';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { LoadingSpinner } from '@eucalyptusvc/design-system';
import { DownloadAppBannerContextProvider } from '@customer-frontend/services';
import { ImpersonatingUserBanner } from '@customer-frontend/auth';

const Throw = React.lazy(() => import('./throw'));
const Account = React.lazy(() => import('./profile/account'));
const Profile = React.lazy(() => import('./profile/main'));
const ProfileConfirmPaymentDetails = React.lazy(
  () => import('./profile/account/confirm-payment-details'),
);
const Login = React.lazy(() => import('./auth/login'));
const Reset = React.lazy(() => import('./auth/reset'));
const ForgetPassword = React.lazy(() => import('./auth/forget-password'));
const Signup = React.lazy(() => import('./auth/signup'));
const Start = React.lazy(() => import('./start/start'));
const StartSignup = React.lazy(() => import('./start/start-signup'));
const StartConfirm = React.lazy(() => import('./start/start-confirm'));
const ContinueToProfile = React.lazy(() => import('./start/continue'));
const Quiz = React.lazy(() => import('./quiz/quiz'));
const QuizComplete = React.lazy(() => import('./quiz/quiz-complete'));
const QuizSummary = React.lazy(() => import('./quiz/quiz-summary'));
const QuizStartGeneral = React.lazy(() => import('./quiz/quiz-start-general'));
const QuizCompleteGeneral = React.lazy(
  () => import('./quiz/quiz-complete-general'),
);
const ConsultationCollectAddress = React.lazy(
  () => import('./consultation/collect-address'),
);
const ConsultationConfirmAnswers = React.lazy(
  () => import('./consultation/confirm-answers'),
);
const ConsultationCreateAccount = React.lazy(
  () => import('./consultation/create-account'),
);
const ConsultationConfirmation = React.lazy(
  () => import('./consultation/confirmation'),
);
const ConsultationChat = React.lazy(
  () => import('./consultation/consultation-chat'),
);
const OrderConfirmation = React.lazy(() => import('./consultation/complete'));
const Reason = React.lazy(() => import('./consultation/reason'));
const OfferingPlan = React.lazy(() => import('./offering/plan'));
const OfferingSwitch = React.lazy(() => import('./offering/switch'));
const OfferingSwitchReceipt = React.lazy(
  () => import('./offering/switch-receipt'),
);
const OfferingNotes = React.lazy(() => import('./offering/notes'));
const OfferingFaq = React.lazy(() => import('./offering/faq'));
const FollowUp = React.lazy(() => import('./consultation/follow-up'));
const ConfirmPathology = React.lazy(
  () => import('./consultation/confirm-pathology'),
);
const ErrorComponent = React.lazy(() => import('./error'));
const PurchaseOfferingsIntentHandler = React.lazy(
  () => import('./purchase-offerings-intent-handler-wrapper'),
);
const RejectedScreen = React.lazy(() => import('./quiz/rejected-screen'));
const CollectMedicare = React.lazy(
  () => import('./consultation/collect-medicare'),
);
const InitialPurchaseConfirm = React.lazy(
  () => import('./consultation/flexiplans/initial-purchase-confirm/default'),
);
const PractitionerPhoneCall = React.lazy(
  () => import('./consultation/practitioner-phone-call'),
);
const SchedulePhoneCall = React.lazy(
  () => import('./consultation/schedule-phone-call'),
);
const FlexiplansDev = React.lazy(() => import('./consultation/flexiplans/dev'));
const MobileLinkHandler = React.lazy(() => import('./mobile-link-handler'));
const SubstitutePurchaseReview = React.lazy(
  () => import('./consultation/flexiplans/substitute-purchase-review-page'),
);
const SubstitutePurchaseConfirm = React.lazy(
  () => import('./consultation/flexiplans/substitute-purchase-confirm-page'),
);
const FollowUpOrReviewPurchaseReview = React.lazy(
  () =>
    import(
      './consultation/flexiplans/follow-up-or-review-purchase-review-page'
    ),
);
const FollowUpOrReviewPurchaseConfirm = React.lazy(
  () =>
    import(
      './consultation/flexiplans/follow-up-or-review-purchase-confirm-page'
    ),
);
const OrderConfirmed = React.lazy(
  () => import('./consultation/flexiplans/order-confirmed'),
);
const SafetyInformation = React.lazy(
  () => import('./consultation/flexiplans/safety-information'),
);
const DoctorLetter = React.lazy(
  () => import('./consultation/flexiplans/doctor-letter'),
);
const Purchase = React.lazy(() => import('./purchase/checkout'));
const PurchaseConfirmation = React.lazy(
  () => import('./purchase/confirmation'),
);
const CallConfirm = React.lazy(
  () => import('./consultation/practitioner-call-confirmed'),
);

const FurConsultChoice = React.lazy(
  () => import('./consultation/fur-consult-choice'),
);
const AsyncConsultConfirmed = React.lazy(
  () => import('./consultation/async-consult-confirmed'),
);

function SuspenseRoute(props: RouteProps): React.ReactElement {
  return (
    <React.Suspense
      fallback={
        <div className="flex justify-center p-5">
          <LoadingSpinner />
        </div>
      }
    >
      <Route {...props} />
    </React.Suspense>
  );
}

function SuspensePrivateRoute(props: PrivateRouteProps): React.ReactElement {
  return (
    <React.Suspense
      fallback={
        <div className="flex justify-center p-5">
          <LoadingSpinner />
        </div>
      }
    >
      <PrivateRoute {...props} />
    </React.Suspense>
  );
}

function Routes(): ReactElement {
  const enableFlexiplansOtc = useFeatureFlagBoolean(
    'ENABLE_FLEXIPLANS_OTC_PURCHASE',
  );

  return (
    <BrowserRouter>
      <CompatRouter>
        <RouteTracker />
        <ScrollToTop />
        <CookieConsentManager />
        <ImpersonatingUserBanner />
        <PersistedDiscountFromURLProvider>
          <DownloadAppBannerContextProvider>
            <NavBarLayout>
              <Switch>
                <SuspenseRoute path={routes.login}>
                  <Login />
                </SuspenseRoute>
                <SuspenseRoute path={routes.forgotPassword}>
                  <ForgetPassword />
                </SuspenseRoute>
                <SuspenseRoute path={routes.reset}>
                  <Reset />
                </SuspenseRoute>
                <SuspenseRoute path={routes.signup}>
                  <Signup />
                </SuspenseRoute>
                <SuspenseRoute path={routes.start} exact>
                  <Start />
                </SuspenseRoute>
                <SuspenseRoute path={routes.startWelcome}>
                  <Redirect
                    to={{
                      pathname: routes.startSignup,
                      search: window.location.search,
                    }}
                  />
                </SuspenseRoute>
                <SuspenseRoute path={routes.startSignup}>
                  <StartSignup />
                </SuspenseRoute>
                <SuspenseRoute path={routes.mobileLink}>
                  <MobileLinkHandler />
                </SuspenseRoute>
                {enableFlexiplansOtc && (
                  <SuspenseRoute exact path="/purchase">
                    <Purchase />
                  </SuspenseRoute>
                )}
                {enableFlexiplansOtc && (
                  <SuspensePrivateRoute
                    exact
                    path="/purchase/:purchaseGroupId/confirmation"
                  >
                    <PurchaseConfirmation />
                  </SuspensePrivateRoute>
                )}
                <SuspensePrivateRoute
                  path={`/handle-purchase-offerings-intent`}
                >
                  <PurchaseOfferingsIntentHandler />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute
                  path={routes.profileAccountConfirmPaymentDetails}
                >
                  <ProfileConfirmPaymentDetails />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path={routes.startConfirm}>
                  <StartConfirm />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path={routes.continueToProfile}>
                  <ContinueToProfile />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path={routes.profile}>
                  <ProfileLayout>
                    <Switch>
                      <SuspenseRoute path={'/profile/treatment'}>
                        <Redirect
                          to={{
                            pathname: routes.profile,
                          }}
                        />
                      </SuspenseRoute>
                      <SuspenseRoute path={routes.profileAccount}>
                        <Account />
                      </SuspenseRoute>
                      <SuspenseRoute>
                        <Profile />
                      </SuspenseRoute>
                    </Switch>
                  </ProfileLayout>
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path="/consultation/:consultationId">
                  <ConsultationRoutes />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path="/offering/:purchaseId/switch">
                  <OfferingSwitch />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path="/offering/:purchaseId/switch-receipt">
                  <OfferingSwitchReceipt />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path="/offering/:purchaseId">
                  <OfferingsRoutes />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path="/quiz">
                  <QuizRoutes />
                </SuspensePrivateRoute>
                <SuspenseRoute path="/" exact>
                  <Redirect
                    to={{
                      pathname: routes.profile,
                    }}
                  />
                </SuspenseRoute>
                <SuspensePrivateRoute path="/call/:pracBookingId/confirmation">
                  <CallConfirm />
                </SuspensePrivateRoute>
                {!environment.isProd && (
                  <SuspensePrivateRoute exact path="/dev/anytime-purchase">
                    <FlexiplansDev />
                  </SuspensePrivateRoute>
                )}
                <SuspenseRoute exact path="/throw/:message">
                  <Throw />
                </SuspenseRoute>
                <SuspenseRoute>
                  <ErrorComponent />
                </SuspenseRoute>
              </Switch>
            </NavBarLayout>
          </DownloadAppBannerContextProvider>
        </PersistedDiscountFromURLProvider>
      </CompatRouter>
    </BrowserRouter>
  );
}

function QuizRoutes(): React.ReactElement {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <SuspenseRoute path={`${path}/start/:quizCode`}>
        <QuizStartGeneral />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/complete/:quizCode`}>
        <QuizCompleteGeneral />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/:quizApplicationId/complete`}>
        <QuizComplete />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/:quizApplicationId/summary`}>
        <QuizSummary />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/:quizApplicationId/rejected`}>
        <RejectedScreen />
      </SuspenseRoute>
      <SuspenseRoute
        path={[
          `${path}/:quizApplicationId/question/:questionId`,
          `${path}/:quizApplicationId`,
        ]}
      >
        <Quiz />
      </SuspenseRoute>
    </Switch>
  );
}

function OfferingsRoutes(): React.ReactElement {
  const { path, params } = useRouteMatch<{ purchaseId: string }>();
  const { purchaseId } = params;

  return (
    <OfferingLayout
      routes={{
        offering: {
          plan: routes.offering.plan(purchaseId),
          notes: routes.offering.notes(purchaseId),
          faq: routes.offering.faq(purchaseId),
        },
        profile: routes.profile,
      }}
    >
      <Switch>
        <SuspenseRoute exact path={`${path}/notes`} component={OfferingNotes} />
        <SuspenseRoute exact path={`${path}/faq`} component={OfferingFaq} />
        <SuspenseRoute component={OfferingPlan} /> {/* Fallback, and /plan */}
      </Switch>
    </OfferingLayout>
  );
}

function ConsultationRoutes(): React.ReactElement {
  const { path } = useRouteMatch<{ consultationId: string }>();

  return (
    <Switch>
      <SuspensePrivateRoute path={`${path}/collect-medicare`}>
        <CollectMedicare />
      </SuspensePrivateRoute>
      <SuspenseRoute path={`${path}/collect-address`}>
        <ConsultationCollectAddress />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/confirm-answers`}>
        <ConsultationConfirmAnswers />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/create-account`}>
        <ConsultationCreateAccount />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/confirmation`}>
        <ConsultationConfirmation />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/chat`}>
        <ConsultationChat />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/complete`}>
        <OrderConfirmation />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/doctors-note`}>
        <DoctorLetter />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/reason`}>
        <Reason />
      </SuspenseRoute>
      <SuspensePrivateRoute path={`${path}/follow-up`}>
        <FollowUp />
      </SuspensePrivateRoute>
      <SuspensePrivateRoute path={`${path}/confirm-pathology`}>
        <ConfirmPathology />
      </SuspensePrivateRoute>
      <SuspenseRoute path={`${path}/review-substitution`}>
        <SubstitutePurchaseReview />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/confirm-substitution`}>
        <SubstitutePurchaseConfirm />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/review-follow-up-or-review`}>
        <FollowUpOrReviewPurchaseReview />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/confirm-follow-up-or-review`}>
        <FollowUpOrReviewPurchaseConfirm />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/order-confirmed`}>
        <OrderConfirmed />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/offering-safety-information`}>
        <SafetyInformation />
      </SuspenseRoute>
      <Redirect from={`${path}/programme`} to={`${path}/payment`} />
      <Redirect from={`${path}/program`} to={`${path}/payment`} />
      <SuspenseRoute path={`${path}/fur-consult-choice`}>
        <FurConsultChoice />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/phone-call`}>
        <PractitionerPhoneCall />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/schedule-call`}>
        <SchedulePhoneCall />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/async-confirmed`}>
        <AsyncConsultConfirmed />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/payment`}>
        <InitialPurchaseConfirm />
      </SuspenseRoute>
      <SuspensePrivateRoute path={`${path}/activate-purchase`}>
        <ActivatePurchaseFlow />
      </SuspensePrivateRoute>
      <SuspensePrivateRoute exact path={path}>
        <Redirect to={routes.profile} />
      </SuspensePrivateRoute>
    </Switch>
  );
}

export default Routes;
