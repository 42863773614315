import {
  buildReadingRoute,
  BuildReadingRouteParams,
} from '@customer-frontend/education';

export const routes = {
  purchase: '/purchase',
  purchaseConfirmation: (purchaseGroupId: string) =>
    `/purchase/${purchaseGroupId}/confirmation`,
  handlePurchaseOfferingsIntent: '/handle-purchase-offerings-intent',
  termsAndConditions: '/terms-and-conditions',
  privacyNotice: '/privacy-notice',
  profile: '/profile',
  profileAccount: '/profile/account',
  profileAccountConfirmPaymentDetails:
    '/profile/account/confirm-payment-details',
  forgotPassword: '/forgot-password',
  signup: '/signup',
  login: '/login',
  reset: '/reset',
  start: '/start',
  startWelcome: '/start/welcome',
  startSignup: '/start/signup',
  startConfirm: '/start/confirm',
  startProblemType: '/start/problem-type',
  startSupport: '/start/support',
  startEnterEmail: '/start/email',
  startEnterFirstName: '/start/first-name',
  startEnterLastName: '/start/last-name',
  continueToProfile: '/start/continue',
  mobileLink: '/mobile-link/:path',
  quiz: {
    root: (id: string): string => `/quiz/${id}`,
    summary: (id: string): string => `/quiz/${id}/summary`,
    rejected: (id: string): string => `/quiz/${id}/rejected`,
    reset: (id: string): string => `/quiz/${id}/reset`,
    general: {
      start: (quizCode: string): string => `/quiz/start/${quizCode}`,
      complete: (quizCode: string): string => `/quiz/complete/${quizCode}`,
    },
  },
  consultation: {
    collectAddress: (id: string): string =>
      `/consultation/${id}/collect-address`,
    collectMedicare: (id: string): string =>
      `/consultation/${id}/collect-medicare`,
    confirmAnswers: (id: string): string =>
      `/consultation/${id}/confirm-answers`,
    createAccount: (id: string): string => `/consultation/${id}/create-account`,
    payment: (id: string): string => `/consultation/${id}/payment`,
    furConsultChoice: (id: string): string =>
      `/consultation/${id}/fur-consult-choice`,
    phoneCall: (id: string): string => `/consultation/${id}/phone-call`,
    scheduleCall: (id: string): string => `/consultation/${id}/schedule-call`,
    chat: (id: string, loadAtTop?: boolean): string =>
      `/consultation/${id}/chat${loadAtTop ? '?load-at-top=true' : ''}`,
    complete: (id: string): string => `/consultation/${id}/complete`,
    doctorsNote: (id: string): string => `/consultation/${id}/doctors-note`,
    confirmation: (id: string): string => `/consultation/${id}/confirmation`,
    reason: (id: string): string => `/consultation/${id}/reason`,
    followUp: (id: string): string => `/consultation/${id}/follow-up`,
    confirmPathology: (id: string): string =>
      `/consultation/${id}/confirm-pathology`,
    reviewSubstitution: (id: string): string =>
      `/consultation/${id}/review-substitution`,
    confirmSubstitution: (id: string): string =>
      `/consultation/${id}/confirm-substitution`,
    reviewFollowUpOrReview: (id: string): string =>
      `/consultation/${id}/review-follow-up-or-review`,
    confirmFollowUpOrReview: (id: string): string =>
      `/consultation/${id}/confirm-follow-up-or-review`,
    orderConfirmed: (id: string): string =>
      `/consultation/${id}/order-confirmed`,
    safetyInformation: (id: string): string =>
      `/consultation/${id}/offering-safety-information`,
    activatePurchaseFlow: {
      review: (id: string): string =>
        `/consultation/${id}/activate-purchase/review`,
      tierSelection: (id: string): string =>
        `/consultation/${id}/activate-purchase/tier-selection`,
      addons: (id: string): string =>
        `/consultation/${id}/activate-purchase/add-ons`,
      addDetails: (id: string): string =>
        `/consultation/${id}/activate-purchase/add-details`,
      confirm: (id: string): string =>
        `/consultation/${id}/activate-purchase/confirm`,
      receipt: (id: string): string =>
        `/consultation/${id}/activate-purchase/receipt`,
    },
    asyncConsultConfirmed: (id: string): string =>
      `/consultation/${id}/async-confirmed`,
    phoneCallConfirmed: (id: string): string => `/call/${id}/confirmation`,
  },
  offering: {
    // These routes are based on purchaseId, but avoid the term 'purchase' in the customer facing URLs,
    // as it's too transactional/commercial for a medical setting
    plan: (purchaseId: string): string => `/offering/${purchaseId}/plan`,
    switch: (purchaseId: string): string => `/offering/${purchaseId}/switch`,
    switchReceipt: (purchaseId: string): string =>
      `/offering/${purchaseId}/switch-receipt`,
    notes: (purchaseId: string): string => `/offering/${purchaseId}/notes`,
    faq: (purchaseId: string): string => `/offering/${purchaseId}/faq`,
  },
  profileSubscriptions: '/app/profile/subscriptions',
  education: {
    reading: (id: string, params?: BuildReadingRouteParams): string =>
      buildReadingRoute(id, params),
  },
};
